import React, { useRef } from 'react'

export default function MultiSelect({ items, value, onChange, value_field, label }) {
    const select = useRef(null);
    const addValue = (event) => {
        if (select.current.value) {
            onChange([...value, select.current.value]);
            select.current.value = 0;
        };
    }
    value = value ?? [];
    const removeValue = (_value) => {

        let __value = [...value];
        onChange(__value.filter((___value) => ___value !== _value));
    };

    const items_names = value.map((item) => {
        return items.filter((_item) => _item[value_field] === item)[0]
    }).map((item) => { return { value: item[value_field], label: label(item) } });

    const _items = items.filter((item) => {
        return !value.filter((_value) => _value === item[value_field]).length
    });
    return (
        <div>
            {items_names.map((item) => <span className="badge" onClick={()=>{removeValue(item.value)}}>{item.label}</span>)}
            <select className="form-control" ref={select} onChange={()=>{addValue()}}>
                <option value="0">Válasz</option>
                {_items.map((item) => <option value={item[value_field]}>{label(item)}</option>)}
            </select>
        </div>
    )
}
