import { useContext } from "react";
import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";

import { CompanyContext } from "../contexts/CompanyContext"

export default function Home() {
  const { companies } = useContext(CompanyContext);

  return (<MainLayout>
    <div className="container">
    <div className="row">
      {companies ? companies.map((company) => <div className="col-md-4 mb-1" key={company.comp_id}><Link to={'/'+company.comp_url_id}><div className="card"><div className="card__head">{company.comp_name}</div></div></Link></div>) : null}
    </div>
    </div>
  </MainLayout>);
}