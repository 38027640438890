import React, { useContext, useState, useEffect, useRef } from 'react'

import { TaskListContext } from "../contexts/TaskListContext";


import Modal from "../components/Modal";
import Comments from "../components/Comments";
import AutoHeightTextarea from "../components/AutoHeightTextarea";


export default function TaskEditModal({ isOpen, toggle, task_id, store }) {
    const { tasks } = useContext(TaskListContext);

    const [task, setTask] = useState({ ...tasks[task_id] });

    const changeTask = (key) => {
        return {
            value: task[key],
            onChange: (e) => {
                setTask({ ...task, [key]: e.target.value });
            }
        };
    };


    return (
        <Modal
            header="Feladat Szerkeztése"
            isOpen={isOpen}
            toggle={toggle}

            body={
                <>

                    <div className="form-group">
                        <label className="form-label" for="project_name">Megnevezés</label>
                        <input type="text" className="form-control" {...changeTask("task_title")} />

                    </div>
                    <div className="form-group">
                        <label className="form-label" for="project_name">Leírás</label>
                        <AutoHeightTextarea style={{ minHeight: '100px' }} {...changeTask("task_desc")} />


                    </div>
                    <button className="btn btn--success mb-1" onClick={() => store(task)}>Mentés</button>
                    {task.task_id ? <div><div class="section__spacer mb-1 mt-1"></div><Comments type="task" id={task.task_id} /></div> : null}
                    {/* <div className="form-group">
                        <label className="form-label" for="project_name">Személyek</label>
                        <select className="form-control" {...changeTask("task_user")}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                    </div> */}
                </>
            } />
    )
}
