
import React, { useContext, useEffect, useState, useRef } from 'react'
import { TaskListContext } from "../contexts/TaskListContext";
import { DndProvider, useDrag, useDrop } from "react-dnd";

import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import MultiBackend, { TouchTransition,Preview } from 'react-dnd-multi-backend';



import Request from '../helpers/Request'
import useCheckMobileScreen from '../helpers/useCheckMobileScreen'
import {
  Link,
  useParams
} from "react-router-dom";





export default function KanbanTable({ taskListId, projectId }) {
  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend
      }
    ]
  };

  const { taskLists, tasks, columns, dispatch } = useContext(TaskListContext);
  const { company_id, project_id } = useParams();
  const isMobileView = useCheckMobileScreen();
  
  const tasklist = taskLists[taskListId];
  const columnsList = tasklist.columns.map((col_id) => columns[col_id]);


  const newColumn = async (tasklist_id, name) => {
    const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + tasklist_id + "/column/new", { tlc_name: name });
    const newColumn = resp.data.newColumn;
    if (newColumn) {
      dispatch({ type: "NEW_TASKLIST_COLUMN", newColumn });
    }

  }

  const hasNative =
document && (document.elementsFromPoint || document.msElementsFromPoint)

function getDropTargetElementsAtPoint(x, y, dropTargets) {
return dropTargets.filter(t => {
  const rect = t.getBoundingClientRect()
  return (
    x >= rect.left &&
    x <= rect.right &&
    y <= rect.bottom &&
    y >= rect.top
  )
})
}



  const newTask = async (column_id, task_title) => {



    const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + taskListId + "/task/new", { task_title, column_id });
    const newTask = resp.data.newTask;

    if (newTask) {
      dispatch({ type: "NEW_TASK", newTask, column_id });
      dispatch({ type: "ADD_TASK_TO_COLUMN", task_id: newTask.task_id, column_id });
    }



  };
  const moveTaskToColumn = async (type, new_column_id, old_column_id, task_id, task_index) => {
    if (type === 'store') {
      await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + taskListId + "/task/setorder", { column_id: new_column_id, old_column_id, task_id, task_index });
    } else {
      dispatch({ type: 'MOVE_TASK_TO_COLUMN', new_column_id, old_column_id, task_id, task_index });
    }



  };

  const moveTaskInColumn = async (type, column_id, task_id, task_index) => {
    if (type === 'store') {
      await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + taskListId + "/task/setorder", { column_id, task_id, task_index });
    } else {
      dispatch({ type: 'MOVE_TASK_IN_COLUMN', column_id, task_id, task_index });
    }

  };


  const [newColumnInputVisible, setNewColumnInputVisible] = useState(false);
  const newColumnInput = useRef(null);

  useEffect(() => {
    if (newColumnInputVisible) {
      newColumnInput.current.focus()
    }
  }, [newColumnInputVisible]);

  const showNewColumn = () => {
    setNewColumnInputVisible(true)
  }

  const setNewColumn = (e) => {
    if (e.keyCode === 13) {
      newColumn(taskListId, e.target.value);
      setNewColumnInputVisible(false)
    }
  }

  
  return (

    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        
      <section className="kanban">
        {columnsList.map(column => (
          <KanbanColumn
            key={'column' + column.tlc_id}
            groupId={column.tlc_id}
            // setGroupTasksOrder={innerSetGroupTasksOrder}
            moveTaskToColumn={moveTaskToColumn}
          >
            <Column
              // setTasks={innerSetTasks}
              newTask={newTask}
              
              // setGroupTasksOrder={innerSetGroupTasksOrder}
              tasks={tasks}
              column={column}
              project_id={projectId}
              tasklist_id={taskListId}
              moveTaskInColumn={moveTaskInColumn}
            />
          </KanbanColumn>
        ))}

        <div>
          <div className="kanban__column">
            {newColumnInputVisible ? <div className="kanban__column__new kanban__column__new--opened" onKeyUp={setNewColumn}><input className="form-control" ref={newColumnInput} /></div> : <div className="kanban__column__new" onClick={showNewColumn}>+ Oszlop</div>}
          </div>
        </div>
      </section>
    </DndProvider>

  );
};

const Column = ({ columntasks, moveTaskInColumn, column, tasks, setTasks, setGroupTasksOrder, newTask, project_id, tasklist_id }) => {
  const { company_id } = useParams();
  const [show_new_item, set_show_new_item] = useState(false);
  const new_item_ref = useRef(null);

  useEffect(() => {

    if (show_new_item) {
      new_item_ref.current.focus()
    }
  }, [show_new_item]);

  const new_item = () => {
    set_show_new_item(true)
  }

  const add_new_item = (e) => {

    if (e.keyCode === 13) {
      newTask(column.tlc_id, e.target.value);

      set_show_new_item(false)
    }
  }
  const generatePreview = (type, item, style) => <div style={style}>Generated</div>;

    
  

  return (<div className="kanban__column">
    <Link to={'/' + company_id + '/projects/' + project_id + "/tasklists/" + tasklist_id + "/column/" + column.tlc_id}>
      <div className="kanban__column__head">{column.tlc_name}</div>
    </Link>
    <div className="kanban__column__items">
      {column.tasks.map((task_id) => tasks[task_id])
        .map((task, index) => (
          <Link to={'/' + company_id + '/projects/' + project_id + "/tasklists/" + tasklist_id + "/task/" + task.task_id} key={'item' + task.task_id}>
            <KanbanItem
              
              id={task.task_id}
              column_id={column.tlc_id}
              moveTaskInColumn={moveTaskInColumn}
              index={index}
            >
              <div className="kanban__column__item">
                <div className="kanban__column__item__head d-flex justify-content-between">
                  <span className="badge badge--sm">#{task.task_id}</span>
                  <span className="badge badge--sm badge--faded">{task.task_cr_date_since}</span>
                  </div>
                <div>{task.task_title} {task.group}</div>
                <div className="kanban__column__item__footer">{
                  task.task_desc ? "..." : null
                }</div>
              </div>
              <Preview generator={generatePreview} />
            </KanbanItem>
            
          </Link>
        ))}
    </div>
    {show_new_item ? <div className="kanban__column__new kanban__column__new--opened" onKeyUp={add_new_item}><input className="form-control" ref={new_item_ref} /></div> : <div className="kanban__column__new" onClick={new_item}>+ Feladat</div>}


  </div>);
}

const KanbanColumn = ({ groupId, changeTaskGroup, children, moveTaskToColumn }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "card",
    drop(item, monitor) {
      moveTaskToColumn('store', groupId, item.column_id, item.id, item.index);
    },
    
    
    hover(item, monitor) {
      if (item.column_id !== groupId) {
        moveTaskToColumn('move', groupId, item.column_id, item.id, item.index);
        item.column_id = groupId;
      }
      return true;
    }
  });
  drop(ref);
  return <div ref={ref} > {children}</div>;
};

const KanbanItem = ({ id, column_id, children, index, moveTaskInColumn }) => {
  const ref = useRef(null);


  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    drop(item) {
      const hoverIndex = index;
      moveTaskInColumn('store', column_id, item.id, hoverIndex);
    },
    hover(item, monitor) {

      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex && column_id === item.column_id) {

        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action

      moveTaskInColumn('move', column_id, item.id, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
      item.column_id = column_id;
    },
  });
  const [{ getItem }, drag] = useDrag({
    type: "card",
    item: { id, column_id, index },
    collect: monitor => ({
      getItem: monitor.getItem(),
    }),
  });
  const opacity = getItem ? getItem.id === id ? 0.2 : 1 : 1;
  drop(drag(ref));
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <div>{children}</div>
    </div>
  );
};