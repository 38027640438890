import React, { useContext } from 'react'
import MainLayout from "../layouts/MainLayout";
import { ProjectContext } from "../contexts/ProjectContext";
import { CompanyContext } from "../contexts/CompanyContext";
import ProjectModal from "../modals/ProjectModal";

import { Link, useParams ,useHistory} from 'react-router-dom'
import UserIconList from "../components/UserIconList";
import Loader from '../components/Loader';



export default function ProjectPage({showProjectModal}) {
    let history = useHistory();
    const { projects,setProject } = useContext(ProjectContext);
    const { company } = useContext(CompanyContext);


    let { company_id, project_id } = useParams();

    const setShowProjectModal = (show) => {
        if (!show) {
            history.push('/' + company_id+"/projects/"+project_id);
        }
    }

    const storeProject = async (_project) => {
        const resp = await setProject(_project)
        if (resp.project && resp.project.pro_id) {
            history.push('/' + company_id + "/projects/" + resp.project.pro_id);
        }
    }
    return ( <MainLayout>
        {!projects[project_id] ? <Loader/> : 
        
        <div className="container">
            <ProjectModal header="Projekt szerkesztése" store={storeProject} isOpen={showProjectModal} toggle={setShowProjectModal} project={projects[project_id]} />
            <div className=" mb-2" data-page="project">
                <div className="section">
                    <div className="section__pre-title-btns"><Link to={'/' + company_id}>{company.comp_name}</Link></div>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="section__title">{projects[project_id].pro_name}</div>
                        <Link to={'/' + company_id + '/projects/'+project_id+"/edit"} ><span className="btn btn--faded btn--sm">Szerkesztés</span></Link>
                    </div>
                    <div className="section__description">
                        <UserIconList users={projects[project_id].users} />
                    </div>
                    <div className="section__spacer"></div>
                </div>
                <div className="section">
                    <div className="row">
                        <div className="col-md-4 mb-1">
                            <Link to={'/' + company_id + '/projects/' + project_id + "/tasklists"}>
                                <div className="card" >
                                    <div className="card__head text-center">
                                        <i className="icon-tasks"></i> Feladatlisták

                        </div>


                                </div>
                            </Link>
                        </div>

                        <div className="col-md-4 mb-1">
                            <Link to={'/' + company_id + '/projects/' + project_id + "/notes"}>
                                <div className="card" >
                                    <div className="card__head text-center">
                                        <i className="icon-notes"></i> Jegyzetek

                        </div>


                                </div>
                            </Link>
                        </div>

                        {/* <div className="col-md-4 mb-1">
                            <div className="card" >
                                <div className="card__head text-center">
                                    <i className="icon-edit"></i> Információk, Leírások
                        </div>


                            </div>
                        </div>
                        <div className="col-md-4 mb-1">

                            <div className="card">
                                <div className="card__head text-center">
                                    <i className="icon-doc"></i> Fájlok, Dokumentumok
                        </div>


                            </div>
                        </div>
                        <div className="col-md-4 mb-1">
                            <div className="card" >
                                <div className="card__head text-center">
                                    <i className="icon-doc-text"></i>  Hírek
                        </div>



                            </div>
                        </div>
                        <div className="col-md-4 mb-1">
                            <div className="card" >
                                <div className="card__head text-center">

                                    Üzenetek
                        </div>

                            </div>
                        </div>
                        <div className="col-md-4 mb-1">
                            <div className="card" >
                                <div className="card__head text-center">
                                    Események
                        </div> 

                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    }
    </MainLayout>
    )
}
