import React, { useContext } from 'react'
import { useParams, Link,useHistory } from 'react-router-dom'

import MainLayout from "../layouts/MainLayout";
import { ProjectContext } from "../contexts/ProjectContext";
import { TaskListContext } from "../contexts/TaskListContext";
import { CompanyContext } from "../contexts/CompanyContext";
import TasklistEditModal from "../modals/TasklistEditModal";
import Loader from "../components/Loader";
import { NotesContext } from '../contexts/NotesContext';

export default function NotesList({ tasklistmodal }) {
    const history = useHistory();
    const { projects } = useContext(ProjectContext);
    const { company } = useContext(CompanyContext);
    const { notes,notesLoading } = useContext(NotesContext);
    
    let { project_id, company_id } = useParams();

    const toggleModals = (show) => {

        if (!show) {
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/notes");
        }
    }
    const storeTaskList =async  (new_tasklist)=>{
        // const resp = await newTaskList(new_tasklist)
        // if (resp.tasklist && resp.tasklist.tasklist_id) {
        //     history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + resp.tasklist.tasklist_id);
        // }
    }

    
    const noteIds =  Object.keys(notes);

    const notesHtml = noteIds.map((note_id) => {
        const note = notes[note_id];
        return <div className="col-md-4 mb-1" key={'note' + note.note_id}>
            <Link to={'/' + company.comp_url_id + '/projects/' + project_id + "/notes/" +  note.note_id}><div className="card tasklists-item">
                <div className="card__head">
                    {note.note_title}
                </div>
                <div className="card__body">
                    {note.note_text}
                </div>
            </div></Link>
        </div>;
    })

    return (!projects[project_id] ? null : <MainLayout>
        <div className="container">
            <div className=" mb-2">
                <div className="section">
                    {/* {tasklistmodal ? <TasklistEditModal store={storeTaskList} isOpen="1" taskList={{}} toggle={toggleModals} /> : null} */}
                    <div className="section__pre-title-btns"><Link to={'/' + company.comp_url_id}>{company.comp_name}</Link> &gt; <Link to={'/' + company.comp_url_id + '/projects/' + project_id}><span>{projects[project_id].pro_name}</span></Link></div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="section__title">Jegyzetek</div>
                        <Link to={'/' + company_id + '/projects/' + project_id + "/notes/new"}><span className="btn btn--primary">Új jegyzet</span></Link>
                    </div>
                    <div className="section__spacer"></div>
                </div>
                <div className="section">
                    <div className="row">
                        {notesLoading ? <Loader /> : notesHtml}
                    </div>
                </div>
            </div>

        </div>

    </MainLayout>
    )
}
