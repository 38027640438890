import { useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router";
import {

    useAuth
} from "../helpers/Auth";


export default function Login() {
    let loginInput = useRef(null);
    let auth = useAuth();


    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");



    const history = useHistory();

    const login = () => {

        auth.signin(email, password, (resp) => {
            if (resp.auth === 'SUCCESS') {
                history.push("/");
            } else {
                alert(resp.msg);
            }

        });

    }

    function formSubmit(e) {
        e.preventDefault();
        login();
    }

    useEffect(() => {
        if (loginInput.current) {
            loginInput.current.focus()
        }
    }, [loginInput])

    return auth.logged ? <Redirect
        to={{
            pathname: "/",

        }}
    /> : <div className="h-100 align-items-center justify-content-center d-flex">


        <div className="card" style={{ width: '400px' }}>
            <div className="card__header">
                {auth.user ? auth.user.u_firstname : ""}
            </div>
            <div className="card__body">
                <form action="/login" method="POST" onSubmit={(e) => formSubmit(e)}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="project_name">E-mail cím</label>
                        <input type="text" id="login" className="form-control" name="email" ref={loginInput} onInput={(e) => { setemail(e.target.value) }} />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="project_name" >Jelszó</label>
                        <input type="password" className="form-control" name="password" onInput={(e) => { setpassword(e.target.value) }} />
                    </div>
                    <div className="d-flex justify-content-between">
                        <button type="submit" className="btn btn--primary" onClick={() => login}>Belépés</button>

                    </div>
                </form>
            </div>

        </div>
    </div>
}
