import React, { useEffect, useState } from 'react'

import AutoHeightTextarea from "../components/AutoHeightTextarea";

export default function Comments({ id, type }) {



    const [text, setText] = useState("");
    const [comments, setComments] = useState([
        {
            id: 1,
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, quod tempora, aut possimus iste cum omnis impedit sit ea aperiam maiores consequuntur iusto sequi labore optio. Reprehenderit, quod ipsum nisi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore, quod tempora, aut possimus iste cum omnis impedit sit ea aperiam maiores consequuntur iusto sequi labore optio. Reprehenderit, quod ipsum nisi.',
            date: '2021-02-02 01:01:01',
            user: 'Szentiványi András'
        }
    ]);

    const addComment = () => {
        setComments([...comments, {
            id: comments.length + 1,
            text:text,
            date: "123",
            user: "ads"
        }]);
        setText("");
    };



    return (<div className="comments">
        <div className="comments__count">{comments.length} hozzászólás</div>
        {comments.map(comment => <div className="comment__item card" key={comment.id}>
            <div className="card-body">
                <div className="comment__item__body">{comment.text}</div>
                <div className="comment__item__footer">
                    {comment.user} | {comment.date}
                </div>
            </div>
        </div>)}


        <div className="card">
            <div className="card-body">
                <div className="comment__send__cont">
                    <AutoHeightTextarea className="comment__textarea" value={text} onChange={(e) => { setText(e.target.value) }} />
                    <button className="btn btn--primary comment__send" onClick={() => addComment()}>Rögzítés</button>
                </div>
            </div>
        </div>
    </div>);
}
