import {useContext} from 'react'
import { CompanyContext} from "../contexts/CompanyContext";
import { useAuth} from "../helpers/Auth";
    
import {
    Link,
  } from "react-router-dom";

   
export default function TopNav() {
  const { company } = useContext(CompanyContext);
  const auth = useAuth();
  
    return <div className="topnav">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="topnav__brand"><Link to="/">Space</Link></div>
          </div>
          
          <div>
            <div className="topnav__item"><Link to={'/'+company.comp_url_id}>{company.comp_name}</Link></div>
            {/* <input type="text" className="form-control" placeholder="Keresés"/> */}

            {/* <div className="topnav__item"><Link to="/">Home</Link></div>
            <div className="topnav__item"><Link to="/tasks">Feladatok</Link></div> */}
            {/* <div className="topnav__item"><Link to="/">Projektek</Link></div> */}

          </div>
          <div>
            <div className="topnav__item dropdown">
              <div className="user-icon">{!!auth.user.u_lastname ? auth.user.u_lastname[0] : ""}{!!auth.user.u_firstname ? auth.user.u_firstname[0] : ""}</div>
              {/* <div className=""dropdown__content">
              <div className=""dropdown__item">Profilom beállítása</div>
              <div className=""dropdown__item">Kijelentkezés</div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>;
  }