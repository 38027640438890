import React, { useState, useContext, createContext } from "react";



import Request from '../helpers/Request'


const fakeAuth = {
  isAuthenticated: false,
  async signin(email, password, cb) {
    const resp = await Request.post("/login", { email, password });
    if (resp.data.auth === 'SUCCESS') {
      fakeAuth.isAuthenticated = true;
    }
    
    cb(resp.data)
  },
  async islogged(cb) {
    const resp = await Request.post("/logged");
    if (resp.data.auth === 'SUCCESS') {
      fakeAuth.isAuthenticated = true;
    }
    
    cb(resp.data)
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};
const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loggincheck, loggincheckSet] = useState(false);

  const signin = (email, password, cb) => {
    return fakeAuth.signin(email, password, (resp) => {
      setUser(resp.user);
      cb(resp);
    });
  };
  const islogged = cb => {
    return fakeAuth.islogged((resp) => {
      if (resp.auth === 'SUCCESS') {
        setUser(resp.user);
      }
      loggincheckSet(true);
      
    });
  };

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };


  return {
    loggincheck,
    user,
    signin,
    signout,
    islogged,
    logged: fakeAuth.isAuthenticated
  };
}

export {
  ProvideAuth,
  useProvideAuth,
  fakeAuth,
  useAuth
}