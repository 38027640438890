import React, { useEffect, useRef } from 'react'

function useOnClickOutside(ref, handler) {
    useEffect(() => {

        const listener = event => {

            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]); // Empty array ensures that effect is only run on mount and unmount
}



export default function Modal({ isOpen, toggle, body, header, footer, onSubmit }) {
    const ref = useRef();


    useOnClickOutside(ref, () => toggle(false));
    const submit = (e) => { e.preventDefault(); if(onSubmit) onSubmit(); }
    return (
        isOpen ? <div className="modal__layout" >
            <div className="modal" ref={ref}>
                <form onSubmit={submit}>
                    <div className="modal__head">
                        <span>{header ? header : null}</span>
                        <span className="modal__close-btn" onClick={() => { toggle(false) }}>x</span>
                    </div>
                    <div className="modal__body">
                        {body}

                    </div>
                    {footer ? <div className="modal__footer">
                        {footer}

                    </div> : null}
                </form>
            </div>
        </div> : null
    )
}

