import React, { useContext } from 'react'
import MainLayout from "../layouts/MainLayout";
import { ProjectContext } from "../contexts/ProjectContext";
import { TaskListContext } from "../contexts/TaskListContext";
import { CompanyContext } from "../contexts/CompanyContext";
import KanbanTable from "../components/KanbanTable";
import TaskEditModal from "../modals/TaskEditModal";
import TasklistEditModal from "../modals/TasklistEditModal";
import TaskColumnEditModal from "../modals/TaskColumnEditModal";
import { useParams } from 'react-router-dom'
import Request from '../helpers/Request'
import {
    Link,
    useHistory
} from "react-router-dom";

export default function ProjectTaskList({ tasklistmodal }) {

    let history = useHistory();

    const { taskLists, editTaskList ,dispatch} = useContext(TaskListContext);
    const { projects } = useContext(ProjectContext);
    const { company } = useContext(CompanyContext);


    let { company_id, project_id, tasklist_id, task_id, column_id } = useParams();


    const tasklist = taskLists[tasklist_id] ? taskLists[tasklist_id] : {};


    const toggleModals = (show) => {

        if (!show) {
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + tasklist_id);
        }
    }
    const storeEditTaskList = async (edited_tasklist) => {
        const resp = await editTaskList(edited_tasklist)
        if (resp.tasklist && resp.tasklist.tasklist_id) {
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + resp.tasklist.tasklist_id);
        }
    }
    const editColumn = async (column) => {
        const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + tasklist_id + "/column/edit", column);
        const editedColumn = resp.data.editedColumn;
        if (editedColumn) {
            dispatch({ type: "EDIT_TASKLIST_COLUMN", editedColumn });
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + tasklist_id);
        }

    }
    const editTask = async (task) => {
        const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + tasklist_id + "/task/edit", task);
        const editedTask = resp.data.editedTask;
        if (editedTask) {
            dispatch({ type: "EDIT_TASK", editedTask });
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + tasklist_id);
        }

    }



return (!projects[project_id] || !taskLists[tasklist_id] ? null : <MainLayout>
    <div className="container">
        <div className=" mb-2">
            <div className="section">

                <div className="section__pre-title-btns"> <Link to={'/' + company.comp_url_id}>{company.comp_name}</Link> &gt; <Link to={'/' + company.comp_url_id + '/projects/' + project_id}><span>{projects[project_id].pro_name}</span> </Link> &gt; <Link to={'/' + company.comp_url_id + '/projects/' + project_id + "/tasklists"}><span>Feladatlisták</span> </Link></div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="section__title">{tasklist.tasklist_name}</div>
                    <Link to={'/' + company_id + '/projects/' + project_id + "/tasklists/" + tasklist_id + "/edit"} ><span className="btn btn--faded btn--sm">Szerkesztés</span></Link>

                </div>
                <div className="section__spacer"></div>
            </div>
        </div>
    </div>
    <div className="container-fluid">
        <div className="section">
            <KanbanTable
                taskListId={tasklist_id}
                projectId={project_id}
            />
        </div>
    </div>

    {task_id ? <TaskEditModal
        isOpen={!!task_id}
        task_id={task_id}
        toggle={toggleModals}
        store={editTask}

    /> : null}
    {tasklistmodal ? <TasklistEditModal
        isOpen="1"
        taskList={tasklist}
        store={storeEditTaskList}
        toggle={toggleModals} /> : null}

    {column_id ? <TaskColumnEditModal
        isOpen={!!column_id}
        column_id={column_id}
        toggle={toggleModals}
        store={editColumn}

    /> : null}
</MainLayout>
)
}
