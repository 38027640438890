import React, { useContext, useState } from 'react'

import { TaskListContext } from "../contexts/TaskListContext";

import Modal from "../components/Modal";

export default function TaskColumnEditModal({ isOpen, toggle, column_id,store }) {
    const { columns } = useContext(TaskListContext);

    const [column, setColumn] = useState({ ...columns[column_id] });

    const changeColumnTitle = (e) => {
        const _column = { ...column, tlc_name: e.target.value };
        setColumn(_column);
    }

    
    return (
        <Modal
            header="Oszlop szerkesztése"
            isOpen={isOpen}
            toggle={toggle}
            footer={
                <>  <button className="btn btn--danger" onClick={() => toggle(false)}>Mégsem</button>
                    <button className="btn btn--success" onClick={()=>store(column)}>Mentés</button>
                </>
            } body={
                <>     <div className="form-group">
                    <label className="form-label" for="project_name">Megnevezés</label>
                    <input type="text" className="form-control" value={column.tlc_name} onInput={changeColumnTitle} />
                </div>
             
    
                </>
            } />
    )
}
