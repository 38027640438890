import TopNav from "../components/TopNav";

export default function MainLayout(props) {
    return (<div className="h-100">
         <TopNav />
         <div className="container-fluid h-100">
        {props.children}
        
        </div>
    </div>)
}
