import React, {  useState } from 'react'




import Modal from "../components/Modal";


export default function TasklistModal({ isOpen, toggle, taskList,store }) {
    const __taskList = taskList.tasklist_id ? taskList : {};
    console.log(taskList);
    const [tasklist, setTasklist] = useState({ ...__taskList });

    const changeTaskList = (key) => {
        return {
            value: tasklist[key],
            onChange: (e) => {
                setTasklist({ ...tasklist, [key]: e.target.value });
            }
        };
    };


    return (
        <Modal
            header={__taskList.tasklist_id ? "Feladatlista Szerkeztése" : "Feladat lista létrehozása"}
            isOpen={isOpen}
            toggle={toggle}
            footer={
                <>
                    <button className="btn btn--success" onClick={() => store(tasklist)}>Mentés</button>
                    <button className="btn btn--danger" onClick={() => toggle(false)}>Mégsem</button>

                </>
            }
            body={
                <>  <div className="form-group">
                    <label className="form-label" for="project_name">Megnevezés</label>
                    <input type="text" className="form-control" {...changeTaskList("tasklist_name")} />
                </div>

                </>
            } />
    )
}
