import React, { createContext, useReducer, useEffect } from "react";
import { useParams } from 'react-router-dom'
import Request from '../helpers/Request'

export const NotesContext = createContext();

export const NotesProvider = (props) => {


    let { company_id, project_id } = useParams();


    const getProjectNotes = async (pro_id) => {
        dispatch({ type: "NOTES_LOADING", loading: true });
        const resp = await Request.get("/" + company_id + "/projects/" + pro_id + "/notes");
        dispatch({ type: "GET_PROJECT_NOTES", pro_id, ...resp.data });
        dispatch({ type: "NOTES_LOADING", loading: false });
    }




    const newNote = async (note) => {
        const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/notes/new", note);
        dispatch({ type: "NEW_NOTE", note: resp.data.note });
        return resp.data;
    }
    const editNote = async (note) => {
        const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/notes/" + note.note_id + "/edit", note);
        dispatch({ type: "EDIT_NOTE", note: resp.data.note });
        return resp.data;
    }

    useEffect(() => {
        getProjectNotes(project_id);
    }, [project_id]);


    const appReducer = (state, action) => {

        switch (action.type) {
            case 'NOTES_LOADING':
                return {
                    ...state,
                    notesLoading: action.loading
                };
            case 'NEW_NOTE':
                return { ...state, notes: { ...state.notes, [action.note.note_id]: action.note } };
            case 'EDIT_NOTE':
                return { ...state, notes: { ...state.notes, [action.note.note_id]: action.note } };
            case 'GET_PROJECT_NOTES':
                return {
                    ...state,
                    notes: { ...state.notes, ...action.notes  },
                };
            
            
            default:
                return state;
        }
    };

    const defaultState = {
        
        notes: {},

        notesLoading: true
    };

    const [state, dispatch] = useReducer(appReducer, defaultState); // dispatch

    const { 
        notes,
        
        notesLoading } = state;

    const providerData = {
        dispatch,

        getProjectNotes,
        newNote,
        editNote,
        notesLoading,
        notes,

    };

    return <NotesContext.Provider value={
        providerData
    }>
        {props.children}
    </NotesContext.Provider>
};