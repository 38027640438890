import React, {  useContext } from 'react'
import MainLayout from "../layouts/MainLayout";
import UserIconList from "../components/UserIconList";
import ProjectModal from "../modals/ProjectModal";
import { Link, useParams, useHistory } from 'react-router-dom'




import { ProjectContext } from "../contexts/ProjectContext";


export default function ProjectList({ newProjectModal }) {
    let history = useHistory();

    const { company_id } = useParams();


    const { projects, newProject } = useContext(ProjectContext);



    const setShowProjectModal = (show) => {
        if (!show) {
            history.push('/' + company_id);
        }
    }

    const storeNewProject = async (_project) => {
        const resp = await newProject(_project)
        if (resp.project && resp.project.pro_id) {
            history.push('/' + company_id + "/projects/" + resp.project.pro_id);
        }
    }
    return (<MainLayout>

        <div className="container">
            {/* <div className="section">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                        <div className="card" >
                            <div className="card__head">
                                <p>teszt company</p>
                            </div>
                            <div className="card__body">
                                <p>leírás</p>
                               <UserIconList users={projec}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="section">
                <h2 className="section__group-title"><span> Csoportok</span>
                    <span className="section__group-title__btn" onclick="modal('new_project_modal','/1/group/new').show();">Új csoport</span>
                </h2>

                <div className="row">
                    <div className="col-md-4 mb-1">
                    <Link to="/projects/1">
                        <div className="card" data-href="/1/2">
                            <div className="card__head">
                                teszt csoport </div>
                            <div className="card__body">
                                <div className="user-icon-list"><div className="user-icon">SA</div></div> </div>
                        </div>
                        </Link>
                    </div>

                    <div className="col-md-4 mb-2">
                    <Link to="/projects/2">
                        <div className="card" data-href="/1/2">
                            <div className="card__head">
                                teszt csoport 2</div>
                            <div className="card__body">
                                <div className="user-icon-list"><div className="user-icon">SA</div></div> </div>
                        </div>
                        </Link>
                    </div>

                </div>

            </div> */}



            {newProjectModal ? <ProjectModal header="Új projekt létrehozása" isOpen={newProjectModal} toggle={setShowProjectModal} project={{}} store={storeNewProject} /> : null }
            <div className="section">
                <h2 className="section__group-title"><span> Projektek</span>
                    <Link to={'/' + company_id + '/projects/new'} ><span className="section__group-title__btn">Új projekt</span></Link>
                </h2>

                <div className="row">
                    {Object.keys(projects).map((project_id) =>
                        <div className="col-md-4 mb-1" key={'project_' + project_id}>
                            <Link to={'/' + company_id + '/projects/' + project_id}>
                                <div className="card">
                                    <div className="card__head">
                                        {projects[project_id].pro_name} </div>
                                    <div className="card__body">
                                        <p>{projects[project_id].pro_desc}</p>
                                        <UserIconList users={projects[project_id].users} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )}


                </div>

            </div>
        </div>

    </MainLayout >

    )
}
