import React, { createContext, useReducer, useEffect } from "react";
import { useParams } from 'react-router-dom'
import Request from '../helpers/Request'

export const TaskListContext = createContext();

export const TaskListProvider = (props) => {


    let { company_id, project_id } = useParams();


    const getProjectTaskLists = async (pro_id) => {
        dispatch({ type: "TASKLISTS_LOADING", loading: true });
        const resp = await Request.get("/" + company_id + "/projects/" + pro_id + "/tasklists");
        dispatch({ type: "GET_PROJECT_TASKLISTS", pro_id, ...resp.data });
        dispatch({ type: "TASKLISTS_LOADING", loading: false });
    }




    const newTaskList = async (tasklist) => {
        const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/new", tasklist);
        dispatch({ type: "NEW_TASKLIST", tasklist: resp.data.tasklist });
        return resp.data;
    }
    const editTaskList = async (tasklist) => {
        const resp = await Request.post("/" + company_id + "/projects/" + project_id + "/tasklists/" + tasklist.tasklist_id + "/edit", tasklist);
        dispatch({ type: "EDIT_TASKLIST", tasklist: resp.data.tasklist });
        return resp.data;
    }

    useEffect(() => {
        getProjectTaskLists(project_id);
    }, [project_id]);


    const appReducer = (state, action) => {

        switch (action.type) {
            case 'TASKLISTS_LOADING':
                return {
                    ...state,
                    tasklistsLoading: action.loading
                };
            case 'NEW_TASKLIST':
                return { ...state, taskLists: { ...state.taskLists, [action.tasklist.tasklist_id]: action.tasklist } };
            case 'EDIT_TASKLIST':
                return { ...state, taskLists: { ...state.taskLists, [action.tasklist.tasklist_id]: action.tasklist } };
            case 'MOVE_TASK_TO_COLUMN':

                let new_tasks_to = [...state.columns[action.new_column_id].tasks];
                new_tasks_to.splice(action.task_index, 0, action.task_id);

                return {
                    ...state,
                    tasks: { ...state.tasks, [action.task_id]: { ...state.tasks[action.task_id], task_order: action.task_index } },
                    columns: {
                        ...state.columns,
                        [action.old_column_id]: { ...state.columns[action.old_column_id], tasks: state.columns[action.old_column_id].tasks.filter((task_id) => task_id !== action.task_id) },
                        [action.new_column_id]: { ...state.columns[action.new_column_id], tasks: new_tasks_to },
                    }

                };
            case 'MOVE_TASK_IN_COLUMN':

                let new_tasks_in = [...state.columns[action.column_id].tasks].filter((task_id) => task_id !== action.task_id);
                new_tasks_in.splice(action.task_index, 0, action.task_id);

                return {
                    ...state,
                    tasks: { ...state.tasks, [action.task_id]: { ...state.tasks[action.task_id], task_order: action.task_index } },
                    columns: {
                        ...state.columns,
                        [action.column_id]: { ...state.columns[action.column_id], tasks: new_tasks_in },
                    }

                };
            case 'GET_PROJECT_TASKLISTS':
                return {
                    ...state,
                    taskLists: { ...state.taskLists, ...action.taskLists },
                    columns: { ...state.columns, ...action.columns },
                    tasks: { ...state.tasks, ...action.tasks },
                    projectTaskListsOrder: { ...state.projectTaskListsOrder, ...action.projectTaskListsOrder },
                };
            case 'SET_GROUP_TASK_ORDER':
                return { ...state, groupTasksOrderData: action.groupTaskorder };

            case 'NEW_TASK':
                return { ...state, tasks: { ...state.tasks, [action.newTask.task_id]: action.newTask } };
            case 'EDIT_TASK':
                return { ...state, tasks: { ...state.tasks, [action.editedTask.task_id]: action.editedTask } };
            case 'ADD_TASK_TO_COLUMN':
                return { ...state, columns: { ...state.columns, [action.column_id]: { ...state.columns[action.column_id], tasks: [...state.columns[action.column_id].tasks, action.task_id] } } };
            case 'NEW_TASKLIST_COLUMN':

                return {
                    ...state,
                    columns: { ...state.columns, [action.newColumn.tlc_id]: action.newColumn },
                    taskLists: { ...state.taskLists, [action.newColumn.tlc_tl_id]: { ...state.taskLists[action.newColumn.tlc_tl_id], columns: [...state.taskLists[action.newColumn.tlc_tl_id].columns, action.newColumn.tlc_id] } },
                };
            case 'EDIT_TASKLIST_COLUMN':

                return {
                    ...state,
                    columns: { ...state.columns, [action.editedColumn.tlc_id]: { ...state.columns[action.editedColumn.tlc_id], ...action.editedColumn } },

                };


            default:
                return state;
        }
    };

    const defaultState = {
        projectTaskListsOrder: {},
        taskLists: {},
        columns: {},
        tasks: {},
        tasklistsLoading: true
    };

    const [state, dispatch] = useReducer(appReducer, defaultState); // dispatch

    const { projectTaskListsOrder,
        taskLists,
        columns,
        tasks,
        tasklistsLoading } = state;

    const providerData = {
        dispatch,

        getProjectTaskLists,
        newTaskList,
        editTaskList,
        // setTaskListsOrder,



        tasklistsLoading,
        projectTaskListsOrder,
        columns,
        taskLists,
        tasks,

    };

    return <TaskListContext.Provider value={
        providerData
    }>
        {props.children}
    </TaskListContext.Provider>
};