import React, { createContext, useReducer, useEffect } from "react";

import Request from '../helpers/Request'
import { useParams, useHistory } from 'react-router-dom'
export const CompanyContext = createContext();

export const CompanyProvider = (props) => {
    const history = useHistory();
    const { company_id } = useParams();

    const getCompanies = async () => {
        const resp = await Request.get("/companies");
        dispatch({ type: "GET_COMPANIES", companies: resp.data.companies });
        if (company_id) {
            const company = resp.data.companies.filter((company) => company_id === company.comp_url_id)[0];
            console.log(company);
            if (company) {
                dispatch({ type: "SELECT_COMPANY", company });
            } else {
                history.push("/");
            }
        }

    }
    useEffect(() => {
            // async function(){
                getCompanies();
            // }
    }, [company_id]);

    const appReducer = (state, action) => {
            console.log(action);
        switch (action.type) {
            case 'GET_COMPANIES':
                return { ...state, companies: action.companies };
            case 'SELECT_COMPANY':
                return { ...state, company: { ...action.company } };
            default:
                return state;
        }
    };

    const defaultState = {
        companies: [],
        company: {},
    };

    const [state, dispatch] = useReducer(appReducer, defaultState); // dispatch

    const {
        companies,
        company,
    } = state;

    const providerData = {
        dispatch,

        getCompanies,

        companies,
        company,
    };
    
    return <CompanyContext.Provider value={
        providerData
    }>
        {props.needValidCompany && !state.company.comp_id ? null : props.children}
    </CompanyContext.Provider>
};