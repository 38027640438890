import React, { useContext, useState } from 'react'


import Modal from "../components/Modal";
import MultiSelect from '../components/MultiSelect';
import { CompanyContext } from '../contexts/CompanyContext';


export default function ProjectModal({ isOpen, toggle, header, project,store }) {
    const __project = project.pro_id ? project : {users:[]} ;
    const [_project, setProject] = useState({...__project,users:__project.users.map((user)=>user.u_id)});
    console.log(_project);
    const changeProject = (key) => {
        
        return {
            value: _project[key],
            onChange: (e) => {
                setProject({ ..._project, [key]: e.target.value });
            }
        };
    };

    const { company } = useContext(CompanyContext);
    
    const users_list = Object.keys(company.users).map((u_id) => company.users[u_id]);



    return (
        <Modal
            header={header}
            isOpen={isOpen}
            toggle={toggle}
            footer={
                <>
                    <button className="btn btn--success" onClick={()=>store(_project)}>Mentés</button>
                    <button className="btn btn--danger" onClick={() => toggle(false)}>Mégsem</button>

                </>
            } body={
                <>     <div className="form-group">
                    <label className="form-label" for="project_name">Megnevezés</label>
                    <input type="text" className="form-control"  {...changeProject("pro_name")} />
                </div>
                    <div className="form-group">
                        <label className="form-label" for="project_name">Leírás</label>
                        <textarea type="text" className="form-control" {...changeProject("pro_desc")}></textarea>
                    </div>
                    <div className="form-group">
                        <label className="form-label" for="project_name">Résztvevők</label>
                        <MultiSelect
                            items={users_list}
                            value={_project["users"]}
                            onChange={(value) => {
                                setProject({ ..._project, "users": value })
                            }}
                            value_field="u_id"
                            label={(user) => user.u_lastname + " " + user.u_firstname}
                        />
                    </div>
                </>
            } />
    )
}
