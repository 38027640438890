import React, { createContext, useReducer, useEffect } from "react";

import Request from '../helpers/Request'
import { useParams } from 'react-router-dom'



export const ProjectContext = createContext();

export const ProjectProvider = (props) => {

    const { company_id } = useParams();

    const getProjects = async () => {
        const resp = await Request.get("/" + company_id + "/projects");
        dispatch({ type: "GET_PROJECTS", projects: resp.data.projects });
    }

    const newProject = async (project) => {
        const resp = await Request.post("/" + company_id + "/projects/new",project);
        dispatch({ type: "NEW_PROJECT", project: resp.data.project });
        return resp.data;
    }
    const setProject = async (project) => {
        const resp = await Request.post("/" + company_id + "/projects/set",project);
        dispatch({ type: "SET_PROJECT", project: resp.data.project });
        return resp.data;
    }

    useEffect(() => {
        getProjects()
    }, [company_id])

    const appReducer = (state, action) => {
        console.log(action);
        switch (action.type) {
            case 'GET_PROJECTS':
                return { ...state, projects: action.projects };
            case 'NEW_PROJECT':
                return { ...state, projects: { ...state.projects,[action.project.pro_id]: action.project }  };
            case 'SET_PROJECT':
                return { ...state, projects: { ...state.projects,[action.project.pro_id]: action.project }  };



            default:
                return state;
        }
    };
    

    const defaultState = {
        projects: {},
    };

    const [state, dispatch] = useReducer(appReducer, defaultState); // dispatch
    console.log(state);
    const { 
        projects,
    } = state;

    const providerData = {
        dispatch,

        getProjects,
        newProject,
        setProject,

        projects,
    };

    return <ProjectContext.Provider value={
        providerData
    }>
        {props.children}
    </ProjectContext.Provider>
};