import React, { useContext } from 'react'
import { useParams, Link,useHistory } from 'react-router-dom'

import MainLayout from "../layouts/MainLayout";
import { ProjectContext } from "../contexts/ProjectContext";
import { TaskListContext } from "../contexts/TaskListContext";
import { CompanyContext } from "../contexts/CompanyContext";
import TasklistEditModal from "../modals/TasklistEditModal";
import Loader from "../components/Loader";

export default function ProjectTaskLists({ tasklistmodal }) {
    const history = useHistory();
    const { projects } = useContext(ProjectContext);
    const { taskLists, projectTaskListsOrder, columns, tasklistsLoading,newTaskList } = useContext(TaskListContext);
    const { company } = useContext(CompanyContext);

    let { project_id, company_id } = useParams();

    const toggleModals = (show) => {

        if (!show) {
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists");
        }
    }
    const storeTaskList =async  (new_tasklist)=>{
        const resp = await newTaskList(new_tasklist)
        if (resp.tasklist && resp.tasklist.tasklist_id) {
            history.push('/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + resp.tasklist.tasklist_id);
        }
    }

    const projectTasklists = projectTaskListsOrder[project_id] ? projectTaskListsOrder[project_id] : [];

    return (!projects[project_id] ? null : <MainLayout>
        <div className="container">
            <div className=" mb-2">
                <div className="section">
                    {tasklistmodal ? <TasklistEditModal store={storeTaskList} isOpen="1" taskList={{}} toggle={toggleModals} /> : null}
                    <div className="section__pre-title-btns"><Link to={'/' + company.comp_url_id}>{company.comp_name}</Link> &gt; <Link to={'/' + company.comp_url_id + '/projects/' + project_id}><span>{projects[project_id].pro_name}</span></Link></div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="section__title">Feladatlisták</div>
                        <Link to={'/' + company_id + '/projects/' + project_id + "/tasklists/new"}><span className="btn btn--primary">Új feladatlista</span></Link>
                    </div>
                    <div className="section__spacer"></div>
                </div>
                <div className="section">
                    <div className="row">
                        {tasklistsLoading ? <Loader /> : projectTasklists.map((tasklist_id) => {
                            if (!taskLists[tasklist_id]) {
                                return null;
                            }
                            return <div className="col-md-4 mb-1" key={'tasklist' + tasklist_id}>
                                <Link to={'/' + company.comp_url_id + '/projects/' + project_id + "/tasklists/" + tasklist_id}><div className="card tasklists-item">
                                    <div className="card__head">
                                        {taskLists[tasklist_id].tasklist_name}
                                    </div>
                                    <div className="card__body">
                                        {taskLists[tasklist_id].columns.map((column_id) => {
                                            return <div className="card__body--infolist__item" key={column_id}>
                                                <span>{columns[column_id].tlc_name}</span><span className="badge">{columns[column_id].tasks.length}</span>
                                            </div>
                                        })}


                                    </div>
                                </div></Link>
                            </div>;
                        })}
                    </div>
                </div>
            </div>

        </div>

    </MainLayout>
    )
}
