import React from 'react'

export default function UserIconList({users}) {
    
    return (
        <div className="user-icon-list">
            {users.map((user)=>
                <div className="user-icon" key={'uil'+user.u_id}>{user.monogram}</div>
        )}
        </div>
    )
}
