import './sass/styles.scss';


import React, { useEffect } from "react";

import Home from "./pages/Home";
import Login from "./pages/Login";

import ProjectTaskLists from "./pages/ProjectTaskLists";
import ProjectList from "./pages/ProjectList";
import ProjectPage from "./pages/ProjectPage";
import ProjectTaskList from "./pages/ProjectTaskList";
import NotesList from "./pages/NotesList";
import Note from "./pages/Note";

import { ProjectProvider } from "./contexts/ProjectContext";
import { TaskListProvider } from "./contexts/TaskListContext";
import { CompanyProvider } from "./contexts/CompanyContext";
import { NotesProvider } from "./contexts/NotesContext";

import {
  ProvideAuth,

  useAuth
} from "./helpers/Auth";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


function PrivateRoute({ children, ...rest }) {

  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
function AppInside() {
  const auth = useAuth();



  useEffect(
    () => {
      if (!auth.logged) {
        auth.islogged();
      }
    },
    [auth],
  )

  return (!auth.loggincheck ? <LoadingPage /> : <Router>


    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <PrivateRoute path="/:company_id">
        <CompanyProvider needValidCompany="1">
          <Switch>

            <PrivateRoute path="/:company_id/projects">
              <ProjectProvider>
                <Switch>
                  <Route path="/:company_id/projects/new">
                    <ProjectList newProjectModal="1" />
                  </Route>
                  <Route path="/:company_id/projects/:project_id/notes">
                    <NotesProvider>
                      <Switch>
                      <Route path="/:company_id/projects/:project_id/notes/:note_id">
                            <Note/>
                        </Route>
                        <Route path="/:company_id/projects/:project_id/notes">
                            <NotesList/>
                        </Route>
                      </Switch>
                    </NotesProvider>
                  </Route>
                  <Route path="/:company_id/projects/:project_id/tasklists">
                    <TaskListProvider>
                      <Switch>
                        <Route path="/:company_id/projects/:project_id/tasklists/new">
                          <ProjectTaskLists tasklistmodal="1" />
                        </Route>
                        <Route path="/:company_id/projects/:project_id/tasklists/:tasklist_id/column/:column_id">
                          <ProjectTaskList />
                        </Route>
                        <Route path="/:company_id/projects/:project_id/tasklists/:tasklist_id/task/:task_id">
                          <ProjectTaskList />
                        </Route>
                        <Route path="/:company_id/projects/:project_id/tasklists/:tasklist_id/edit">
                          <ProjectTaskList tasklistmodal="1" />
                        </Route>
                        <Route path="/:company_id/projects/:project_id/tasklists/:tasklist_id">
                          <ProjectTaskList />
                        </Route>
                        <Route path="/:company_id/projects/:project_id/tasklists">
                          <ProjectTaskLists />
                        </Route>
                      </Switch>
                    </TaskListProvider>
                  </Route>
                  <Route path="/:company_id/projects/:project_id/edit">
                    <ProjectPage showProjectModal="1" />
                  </Route>
                  <Route path="/:company_id/projects/:project_id">
                    <ProjectPage />
                  </Route>

                </Switch>
              </ProjectProvider>
            </PrivateRoute>

            <ProjectProvider>
              <ProjectList />
            </ProjectProvider>
          </Switch>
        </CompanyProvider>
      </PrivateRoute>


      <PrivateRoute path="/">
        <CompanyProvider>
          <Home />
        </CompanyProvider>
      </PrivateRoute>
    </Switch>

  </Router >);
}
export default function App() {



  return (

    <ProvideAuth>
      <AppInside />
    </ProvideAuth>

  );
}



// function Topics() {
//   let match = useRouteMatch();
//   return (
//     <div>
//       <h2>Topics</h2>
//       <ul>
//         <li>
//           <Link to={`${match.url}/components`}>Components</Link>
//         </li>
//         <li>
//           <Link to={`${match.url}/props-v-state`}>
//             Props v. State
//           </Link>
//         </li>
//       </ul>

//       {/* The Topics page has its own <Switch> with more routes
//           that build on the /topics URL path. You can think of the
//           2nd <Route> here as an "index" page for all topics, or
//           the page that is shown when no topic is selected */}
//       <Switch>
//         <Route path={`${match.path}/:topicId`}>
//           <Topic />
//         </Route>
//         <Route path={match.path}>
//           <h3>Please select a topic.</h3>
//         </Route>
//       </Switch>
//     </div>
//   );
// }

// function Topic() {
//   let { topicId } = useParams();
//   return <h3>Requested topic ID: {topicId}</h3>;
// }






function LoadingPage() {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center ">
      <p style={{ fontSize: '50px', opacity: 0.2 }}>Welcome to the Space</p>
    </div>
  )
}

