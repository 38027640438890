import React, { useEffect, useRef,useState } from 'react'

export default function AutoHeightTextarea({ ...etc }) {
    const textareaRef = useRef(null);
    const [currentValue, setCurrentValue] = useState(etc.value);// you can manage data with it

    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [currentValue]);


    useEffect(() => {
        setCurrentValue(etc.value);
    }, [etc.value]);

    return (
        <textarea
            ref={textareaRef}

            {...etc}
            value={currentValue}
            className={"form-control" + (etc.className ? " "+etc.className : "") }
            onChange={e => {
                etc.onChange(e);
                setCurrentValue(e.target.value)
            }
            }
        />
    );
};