import React from 'react'

export default function Loader() {
    return (
        
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <div className="loader"><div></div><div></div><div></div></div>
        </div>
        
    )
}
